import { Ref } from 'vue';
import {
  Order,
  OrderEventNames,
  OrderLocalStatus,
  ordersServicePlugin,
  parseOrderBeforeSave,
  useOnlineStatus,
  emitActionChangeEvent,
} from '@/features/orders';
import { errorPlugin } from '@/features/core/errors';
import { appCrashServicePlugin } from '@/features/app-crash';
import router from '@/features/core/router';
import { loggerServicePlugin } from '@/features/core/logger';
import { updateOrderTimestamp } from '@/utils/helpers/updateOrderTimestamp';
import { PickingCompletedSave } from '../types';

export function pickingCompletedSave(): PickingCompletedSave {
  const { isOnline } = useOnlineStatus();

  const save = async (order: Ref<Order | undefined>): Promise<void> => {
    emitActionChangeEvent('picking-completed');
    loggerServicePlugin
      .get()
      .debug(
        `The user confirms the complete picking for Order ${String(
          order?.value?.id,
        )}}`,
      );

    if (order && order.value) {
      order.value = await ordersServicePlugin
        .get()
        .trackEvent(order.value, OrderEventNames.staging_completed, {
          skipSaving: true,
        });

      loggerServicePlugin
        .get()
        .debug(`Events added to the order ${order.value.id}.`, {
          order: order.value,
        });

      order.value.localStatus = OrderLocalStatus.PickingCompleted;

      loggerServicePlugin
        .get()
        .debug(
          `Order status changed to ${order.value.localStatus} for order ${order.value.id}`,
          { order: order.value },
        );
      updateOrderTimestamp(order.value);
      loggerServicePlugin
        .get()
        .debug(`Order timestamp changed for order ${order.value.id}`, {
          order: order.value,
        });
      await appCrashServicePlugin.get().updateProcessedOrderData(order.value);
      loggerServicePlugin
        .get()
        .debug(`Order crash feature unset for order ${order.value.id}`, {
          order: order.value,
        });

      try {
        const parsedOrder = parseOrderBeforeSave(Order.from(order.value));
        loggerServicePlugin
          .get()
          .debug(`Order ${order.value.id} prepared for saving`, {
            order: order.value,
          });

        await ordersServicePlugin.get().saveOrder(parsedOrder);
        loggerServicePlugin
          .get()
          .info(
            `Picking for Order ${order.value.id} completed ${
              isOnline.value ? '' : '(offline)'
            }`,
            {
              orderId: order.value.id,
              orderReference: order.value.orderReference,
            },
          );
      } catch (error) {
        loggerServicePlugin
          .get()
          .error(`Order ${order.value.id} couldn't be saved`, error);
        errorPlugin.get().handle(error);
      } finally {
        await router.get().push('/');
      }
    }
  };

  return {
    save,
  };
}
