import { computed, ref, watch, WatchStopHandle } from 'vue';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { Order, OrderBag } from '@/features/orders';
import { UseOrder } from '../types';

export function useOrder(): UseOrder {
  const order = ref<Order | undefined>();
  const orderId = ref('');
  const loading = ref(false);

  let watcher: WatchStopHandle | null = null;

  const setNumberOfLabels = (quantity: number) => {
    if (order.value) {
      order.value.numberOfLabels = quantity;
    }
  };

  const setBagQuantity = (bag: OrderBag, quantity: number) => {
    bag.quantity = quantity;
  };

  const loadOrder = async (id: string): Promise<void> => {
    loading.value = true;
    orderId.value = id;
    const result = await entityRepositoryPlugin.get().getById(Order, {
      id,
    });
    order.value = result.value;

    if (watcher) {
      watcher();
      watcher = null;
    }

    watcher = watch(result, () => {
      order.value = result.value;
    });
    loading.value = false;
  };

  const bags = computed(() => {
    return order.value?.bags;
  });

  const boxBagsNeeded = computed(() => {
    return (
      order.value?.bags.reduce((acc: number, item) => {
        acc += item.quantity;
        return acc;
      }, 0) || 0
    );
  });

  return {
    order,
    loadOrder,
    boxBagsNeeded,
    setBagQuantity,
    setNumberOfLabels,
    bags,
    loading,
  };
}
