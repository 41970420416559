import { Ref, ComputedRef } from 'vue';
import { Order, OrderBag } from '@/features/orders';
import { $t } from '@/i18n';

export interface UseOrder {
  order: Ref<Order | undefined>;
  loadOrder: (id: string) => Promise<void>;
  boxBagsNeeded: ComputedRef<number>;
  setBagQuantity: (item: OrderBag, quantity: number) => void;
  setNumberOfLabels: (quantity: number) => void;
  bags: ComputedRef<OrderBag[] | undefined>;
  loading: Ref<boolean>;
}

export interface PickingCompletedSave {
  save: (order: Ref<Order | undefined>) => Promise<void>;
}

export const basicTemperatureClasses = {
  freezer: {
    label: $t('components.review-types.temperature-classes.freezer.label'),
    temperatureClass: 'Freezer',
  },
  meat: {
    label: $t('components.review-types.temperature-classes.meat.label'),
    temperatureClass: 'Chiller',
  },
  shelf: {
    label: $t('components.review-types.temperature-classes.shelf.label'),
    temperatureClass: 'Shelf',
  },
};

export enum PrinterFeatureOptions {
  ZebraBrowserPrinter = 'Zebra Browser Printer',
  ZebraQrCodePrinter = 'Zebra QR Code Printer',
}
